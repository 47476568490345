import React, { useState, useEffect } from 'react'
import ColorSelector from '../shared/ColorSelector'
import './ProductListElement.css'

function ProductListElement(props) {

    /* *** STATE *** */
    const [imgURL, setImgURL] = useState(props.product.colors ? props.product.colors[0].imageURLs[0] : props.product.imageURLs[0])
    const [code, setCode] = useState(props.product.colors ? props.product.colors[0].code : props.product.code)

    useEffect(() => {
        if (props.product.colors) {
            setImgURL(props.product.colors[0].imageURLs[0])
            setCode(props.product.colors[0].code)
        } else {
            setImgURL(props.product.imageURLs[0])
            setCode(props.product.code)
        }
    }, [props.product]) //Only adjust productListElement when props.product change

    /* *** FUNCTION CREATION *** */
    const changeColor = clickedColor => {
        props.product.colors.forEach(color => {
            if (color.name === clickedColor) {
                setImgURL(color.imageURLs[0])
                setCode(color.code)
            }
        })
    }

    const openProductCard = () => {
        props.showProductCard(props.product)
    }

    /* *** RENDERING *** */
    return (
        <div className="product-list-element" style={{ ...props.style }}>
            { props.product.filters.marca ? props.product.filters.marca.toLowerCase() === 'flash' ? <img src="../imgs/electrico/productos/france.svg" style={{ marginLeft: 'auto', width: '26px', height: '17px' }} alt="Made in France" /> : null : null }
            <div className="product-list-element-img" style={{backgroundImage: 'url('+ imgURL + ')'}} onClick={openProductCard}></div>
            <div className="product-list-element-text" >
                <div className="product-list-element-bottom">
                    <p className="product-list-element-line" onClick={openProductCard}>{props.product.line.toUpperCase()}</p>
                    <p className="product-list-element-code">{code}</p>
                </div>
                {props.product.colors ? <ColorSelector colors={props.product.colors.map(color => color.name)} changeColor={changeColor} circleSize='15px' style={{ width: props.product.colors.length * 25 + "px", margin: 'auto' }} /> : props.product.logo ? <img src={'./imgs/' + props.product.logo.toLowerCase() + '.jpg'} alt={props.product.logo} style={{ height: "20px" }} /> : null}
            </div>
        </div>
    )

}

export default ProductListElement