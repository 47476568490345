import React from 'react'
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import './App.css'
import Home from './components/sites/home'
import NavBar from './components/shared/NavBar'
import HomeView from './components/home/HomeView'
import HistoryView from './components/history/HistoryView'
import ProductsView from './components/products/ProductsView'
import ServiceView from './components/service/ServiceView'
import ContactView from './components/contact/ContactView'

function App() {

  const { pathname } = useLocation();

  return (
    <div className="App">
      <div id="main-content">
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

          <Route exact path="/"><Home /></Route>

          <Route exact path="/controls"><NavBar logo='../imgs/logo-controls.svg' home="/electrico" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><HomeView home="/electrico" /></Route>
          <Route exact path="/controls/historia"><NavBar logo='../imgs/logo-controls.svg' home="/electrico" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><HistoryView home="/electrico" /></Route>
          <Route exact path="/controls/productos"><NavBar logo='../imgs/logo-controls.svg' home="/electrico" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><ProductsView home="/electrico" selectedFilter={""} /></Route>
          <Route exact path="/controls/service"><NavBar logo='../imgs/logo-controls.svg' home="/electrico" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><ServiceView home="/electrico" /></Route>
          <Route exact path="/controls/contacto"><NavBar logo='../imgs/logo-controls.svg' home="/electrico" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><ContactView home="/electrico" /></Route>

          <Route exact path="/relojes"><NavBar logo='../imgs/logo.svg' home="/relojes" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><HomeView home="/relojes" /></Route>
          <Route exact path="/relojes/historia"><NavBar logo='../imgs/logo.svg' home="/relojes" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><HistoryView home="/relojes" /></Route>
          <Route exact path="/relojes/productos"><NavBar logo='../imgs/logo.svg' home="/relojes" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><ProductsView home="/relojes" selectedFilter={""} /></Route>
          <Route exact path="/relojes/service"><NavBar logo='../imgs/logo.svg' home="/relojes" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><ServiceView home="/relojes" /></Route>
          <Route exact path="/relojes/contacto"><NavBar logo='../imgs/logo.svg' home="/relojes" buttons={['HISTORIA', 'PRODUCTOS', 'SERVICE', 'CONTACTO']} /><ContactView home="/relojes" /></Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;