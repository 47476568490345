import React, { useState, useEffect, useRef } from 'react';
import ProductListElement from './ProductListElement';
import Pagination from './Pagination';
import ProductCard from './product/ProductCard'
import './ProductList.css'

function ProductList(props) {

  //State
  const [dividedProducts, setDividedProducts] = useState(Array.from({ length: Math.ceil(props.products.length / 12) }, (v, i) => props.products.slice(i * 12, i * 12 + 12)))
  const [displayedProducts, setDisplayedProducts] = useState(dividedProducts[0])
  const [currentPage, setCurrentPage] = useState(0)
  const [productInCard, setProductInCard] = useState(props.products[0])
  const [displayProductCard, setDisplayProductCard] = useState(false)

  //Change page
  const changePage = (adding) => {
    setCurrentPage((prevPage) => adding ? prevPage + 1 : prevPage - 1)
    window.innerHeight < 580 ? window.scrollTo(0, 280) : window.scrollTo(0, (window.innerHeight * 6 / 10) - 70)
  }

  //Show/close product card
  const cardContainerRef = useRef(null)

  const showProductCard = (product) => {
    setProductInCard(product)
    setDisplayProductCard(true)
  }

  const closeProductCard = () => {
    cardContainerRef.current.style.opacity = 0
    setDisplayProductCard(false)
  }

  useEffect(() => {
    if (displayProductCard) cardContainerRef.current.style.opacity = 1
  }, [displayProductCard])

  //Defining pages on props.products or page change
  useEffect(() => {
    setDividedProducts(Array.from({ length: Math.ceil(props.products.length / 12) }, (v, i) => props.products.slice(i * 12, i * 12 + 12)))
    setCurrentPage(0)
  }, [props.products])

  useEffect(() => {
    setDisplayedProducts(dividedProducts[currentPage])
  }, [currentPage, dividedProducts])

  return (
    <div id="products-list-container" style={{ ...props.style }}>
      {displayedProducts.map((product, index) => (<ProductListElement product={product} key={index} showProductCard={showProductCard} />))}
      {dividedProducts.length > 1 ? <Pagination pages={dividedProducts.length} currentPage={currentPage + 1} clickHandler={changePage} /> : null}
      <div id="products-list-product-card-container" ref={cardContainerRef} style={displayProductCard ? { display: 'flex' } : { display: 'none' }} ><ProductCard id="products-list-product-card" product={productInCard} closeHandler={closeProductCard} home={props.home} /></div>
    </div>
  );
}

export default ProductList;