import React, { useState, useMemo, useEffect, useCallback } from 'react';
import SectionCover from '../shared/SectionCover'
import CoverBar from '../shared/CoverBar';
import FilterList from './FilterList';
import ProductList from './ProductList';
import './ProductsView.css'
import relojesProductsFromFile from '../../static/relojesProductsFile.json';
import electricoProductsFromFile from '../../static/electricoProductsFile.json';

function ProducstView(props) {

  const productsFromFile = props.home === '/electrico' ? electricoProductsFromFile : relojesProductsFromFile

  //INNER FUNCTIONS

  const generateFilters = (products) => {
    const filters = []
    const productsFiltersKeys = Object.keys(products[0].filters)
    productsFiltersKeys.forEach(key => {
      filters.push({ title: key.toUpperCase(), buttons: [] })
    })
    products.forEach(product => {
      filters.forEach(filter => {
        if (!filter.buttons.includes(product.filters[filter.title.toLowerCase()].toUpperCase()) && product.filters[filter.title.toLowerCase()] !== '') filter.buttons.push(product.filters[filter.title.toLowerCase()].toUpperCase())
      })
    })
    filters.forEach(filter => filter.buttons.sort())
    return filters
  }

  const filterProducts = (activeFilters) => {
    let finalFilteredProducts = productsFromFile
    let activeFiltersAmount = 0
    activeFilters.forEach(activeFilter => { if (activeFilter[1].length === 0) activeFiltersAmount++ })
    if (activeFiltersAmount !== activeFilters.length) {
      activeFilters.forEach(filter => {
        if (filter[1].length > 0) {
          const filteredProducts = []
          filter[1].forEach(filterName => {
            finalFilteredProducts.forEach(product => {
              if (filterName.toLowerCase() === product.filters[filter[0].toLowerCase()].toLowerCase()) {
                const isAlreadyAdded = filteredProducts.find(filteredProduct => filteredProduct.id === product.id)
                if (!isAlreadyAdded) { filteredProducts.push(product) }
              }
            })
          })
          finalFilteredProducts = filteredProducts
        }
      })
    }

    if (props.home === '/relojes') {
      finalFilteredProducts.sort((a, b) => a.id < b.id ? 1 : -1)
      finalFilteredProducts.sort((a, b) => a.filters.material.toLowerCase() === 'madera' && b.filters.material.toLowerCase() !== 'madera' ? -1 : 1)
      finalFilteredProducts.sort((a, b) => a.filters.material.toLowerCase() === 'cuero' && b.filters.material.toLowerCase() !== 'cuero' ? -1 : 1)
      finalFilteredProducts.sort((a, b) => (a.filters.material.toLowerCase() === 'acero inoxidable' || a.filters.material.toLowerCase() === 'metal' || a.filters.material.toLowerCase() === 'titanio') && (b.filters.material.toLowerCase() !== 'acero inoxidable' && b.filters.material.toLowerCase() !== 'metal' && b.filters.material.toLowerCase() !== 'titanio') ? -1 : 1)
      finalFilteredProducts.sort((a, b) => a.filters.display.toLowerCase() === 'analógico/digital' && b.filters.display.toLowerCase() !== 'analógico/digital' ? -1 : 1)
      finalFilteredProducts.sort((a, b) => a.filters.display.toLowerCase() === 'analógico' && b.filters.display.toLowerCase() !== 'analógico' ? -1 : 1)
      finalFilteredProducts.sort((a, b) => a.filters.tipo.toLowerCase() === 'pared' && b.filters.tipo.toLowerCase() !== 'pared' ? -1 : 1)
      finalFilteredProducts.sort((a, b) => a.filters.tipo.toLowerCase() === 'despertador' && b.filters.tipo.toLowerCase() !== 'despertador' ? -1 : 1)
      finalFilteredProducts.sort((a, b) => a.filters.tipo.toLowerCase() === 'pulsera' && b.filters.tipo.toLowerCase() !== 'pulsera' ? -1 : 1)
    } else {
      finalFilteredProducts.sort((a, b) => {
        const givePriority = product => {
          if (product.filters.tipo.toLowerCase() === 'aut general escalera') return 0
          if (product.filters.tipo.toLowerCase() === 'protectores de tensión') return 1
          if (product.filters.tipo.toLowerCase() === 'interruptores' && product.filters.instalación.toLowerCase() === 'enchufable' && product.filters.sistema.toLowerCase() === 'digital' && product.filters.marca.toLowerCase() === 'diletta') return 2
          if (product.filters.tipo.toLowerCase() === 'interruptores' && product.filters.instalación.toLowerCase() === 'enchufable' && product.filters.sistema.toLowerCase() === 'mecánico' && product.filters.marca.toLowerCase() === 'diletta') return 3
          if (product.filters.tipo.toLowerCase() === 'interruptores' && product.filters.instalación.toLowerCase() === 'riel din' && product.filters.sistema.toLowerCase() === 'digital' && product.filters.marca.toLowerCase() === 'diletta') return 4
          if (product.filters.tipo.toLowerCase() === 'interruptores' && product.filters.instalación.toLowerCase() === 'riel din' && product.filters.sistema.toLowerCase() === 'mecánico' && product.filters.marca.toLowerCase() === 'diletta') return 5
          if (product.filters.tipo.toLowerCase() === 'contactores') return 6
          if (product.filters.tipo.toLowerCase() === 'termostatos' && product.filters.sistema.toLowerCase() === 'digital') return 7
          if (product.filters.tipo.toLowerCase() === 'termostatos' && product.filters.sistema.toLowerCase() === 'mecánico') return 8
          if (product.filters.marca.toLowerCase() === 'flash') return 9
        }
        const aWeight = givePriority(a)
        const bWeight = givePriority(b)
        return aWeight === bWeight ? b.id - a.id : aWeight - bWeight
      })
    }

    return finalFilteredProducts
  }

  //STATE
  const filters = useMemo(() => generateFilters(productsFromFile), [productsFromFile])
  const [activeFilters, setActiveFilters] = useState(Object.keys(productsFromFile[0].filters).map(key => [key.toUpperCase(), []]), [])
  const [displayedProducts, setDisplayedProducts] = useState(filterProducts(activeFilters))

  //HANDLERS
  const filterButtonClickHandler = useCallback((isAdded, filterIndex, buttonName) => {
    const currentActiveFilters = activeFilters
    if (isAdded) {
      currentActiveFilters[filterIndex][1].push(buttonName)
    } else {
      const index = currentActiveFilters[filterIndex][1].indexOf(buttonName)
      if (currentActiveFilters[filterIndex][1].length > 0) {
        currentActiveFilters[filterIndex][1].splice(index, 1);
      } else {
        currentActiveFilters[filterIndex][1] = []
      }
    }
    const currentProducts = filterProducts(currentActiveFilters)
    setActiveFilters(currentActiveFilters)
    setDisplayedProducts(currentProducts)
  }, [activeFilters])

  useEffect(() => {
    document.title = 'Productos | Eurotime'
  }, [])

  useEffect(() => {
    if (props.selectedFilter) filterButtonClickHandler(true, 0, props.selectedFilter)
  }, [filterButtonClickHandler, props.selectedFilter])

  return (
    <div className="ProductsView">
      <SectionCover image={'url(../imgs' + props.home + '/productos/products-cover.jpg)'} home={props.home} position="center left" />
      <CoverBar home={props.home} />
      {props.home === '/relojes' ? <h3 id="products-view-title">CONOZCA TODOS NUESTROS PRODUCTOS DE RELOJERÍA</h3> : <h3 id="products-view-title">CONOZCA TODOS NUESTROS MATERIALES ELÉCTRICOS</h3>}
      <div id="products-view-container" style={{ ...props.style }}>
        <FilterList filters={filters} filterButtonClickHandler={filterButtonClickHandler} />
        {displayedProducts.length > 0 ? <ProductList products={displayedProducts} home={props.home} /> : <p className="products-view-null-list">No existen productos que coincidan con este criterio de filtro</p>}
      </div>
    </div>
  );
}

export default ProducstView