import React, { useEffect } from 'react'
import './HomeView.css'
import Carousel from '../shared/Carousel'
import { Link } from 'react-router-dom'

function HomeView(props) {

  useEffect(() => { document.title = 'Eurotime' }, [])

  const content = props.home === '/relojes' ? [
    <Link to='/relojes/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><p className="relojes-p">SIEMPRE LISTO PARA NUEVOS DESAFÍOS</p></div></Link>,
    <Link to='/relojes/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><p className="relojes-p">COMPROMETIDO CON TU TIEMPO</p></div></Link>,
    <Link to='/relojes/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><p className="relojes-p">EL VALOR DEL TIEMPO</p></div></Link>,
    <Link to='/relojes/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><p className="relojes-p">DISFRUTÁ CADA MOMENTO</p></div></Link>,
    <Link to='/relojes/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><p className="relojes-p">ENCONTRÁ TU ESTILO</p></div></Link>,
    <Link to='/relojes/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><p className="relojes-p">100% PASIÓN</p></div></Link>
  ] : [
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">PROTEGE A LOS TUYOS</p><div style={{ backgroundImage: "url(./imgs/electrico/home/4/4.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', maxWidth: '800px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>,
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">AUTOMATIZACIÓN GENERAL DE LUCES EN EDIFICIOS</p><div style={{ backgroundImage: "url(./imgs/electrico/home/5/5.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', maxWidth: '400px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>,
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">TU ALIADO EN LA INDUSTRIA</p><div style={{ backgroundImage: "url(./imgs/electrico/home/1/1.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '90%', maxWidth: '800px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>,
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">PROTECTOR DE TENSIÓN IDEAL PARA CALDERAS MURALES</p><div style={{ backgroundImage: "url(./imgs/electrico/home/4/4.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', maxWidth: '500px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>,
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">LA SOLUCIÓN PARA LA AUTOMATIZACIÓN DE SISTEMAS DE RIEGO</p><div style={{ backgroundImage: "url(./imgs/electrico/home/2/2.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', maxWidth: '500px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>,
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">MAS DE 40 AÑOS EN EL MERCADO DE REFRIGERACION  AVALAN NUESTRA EXPERIENCIA</p><div style={{ backgroundImage: "url(./imgs/electrico/home/6/6.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', maxWidth: '1000px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>,
    <Link to='/controls/productos' style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '100px 25px 75px 25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}><p className="electrico-p">CALEFACCIONÁ TU HOGAR, NOSOTROS CUIDAMOS TU CONSUMO ENERGÉTICO</p><div style={{ backgroundImage: "url(./imgs/electrico/home/3/3.2.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', maxWidth: '500px', height: '350px', maxHeight: '40%', margin: '25px 0 auto 0' }}></div></div></Link>
  ]

  return (
    <div id="home-container">
      <Carousel imageURLs={props.home === '/relojes' ? [['./imgs/relojes/home/5.jpg', 'center', 'cover'], ['./imgs/relojes/home/1.jpg', 'right center', 'cover'], ['./imgs/relojes/home/6.jpg', 'center', 'cover'], ['./imgs/relojes/home/2.jpg', 'center', 'cover'], ['./imgs/relojes/home/4.jpg', 'center', 'cover'], ['./imgs/relojes/home/3.jpg', 'center', 'cover']] : [['./imgs/electrico/home/8/8.png', 'center', 'cover'], ['./imgs/electrico/home/5/5.jpg', 'center', 'cover'], ['./imgs/electrico/home/1/1.jpg', 'center', 'cover'], ['./imgs/electrico/home/4/4.jpg', 'center', 'cover'], ['./imgs/electrico/home/2/2.jpg', 'center', 'cover'], ['./imgs/electrico/home/6/6.jpg', 'center', 'cover'], ['./imgs/electrico/home/3/3.jpg', 'center', 'cover']]} content={content} circles={true} autoplay={true} imageSize={'cover'} imageWidth={'100%'} imageHeight={'100vh'} />
    </div>
  );
}

export default HomeView;