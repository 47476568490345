import React, { useEffect } from 'react';
import SectionCover from '../shared/SectionCover'
import './ServiceView.css'

function ServiceView(props) {

    useEffect(() => { document.title = 'Service | Eurotime' }, [])

    return (
        <div id="service-container" style={{ ...props.style }}>
            <SectionCover image={(props.home === '/relojes' ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), " : '') + "url(../imgs" + props.home + "/service/service-cover.jpg)"} title='' position="center" />
            <div id="service-inner-container">
                <div id="service-box">
                    <h2 id="service-title">NUESTRO SERVICE</h2>
                    <div id="service-main">
                        {props.home === '/relojes' ?
                            <p>En Eurotime contamos con relojeros especializados para garantizarle un excelente servicio de post venta.
                    Nuestro equipo se encuentra a su disposición tanto para despejar cualquier duda sobre su reloj Eurotime, como para resolver algún desperfecto.<br /><br />
                    Nuestros relojes cuentan con 2 años de garantía.
                    La garantía tendrá validez con la presentación de la correspondiente documentación de compra.
                    Quedan exceptuadas de esta garantía todas las fallas, roturas, accidentes o desgastes producidos por el uso indebido del producto, así mismo la apertura del reloj para intento de reparación o reparación por terceros.
                    No cubre caja, malla, vidrio ni batería.<br /><br />
                    Los relojes Eurotime están fabricados con materiales seleccionados, acordes a las necesidades funcionales y de diseño de cada modelo, teniendo presentes la innovación y el avance de la tecnología como ejes de nuestra selección.</p>
                            : <p>En Eurotime contamos con técnicos especializados para garantizarle un excelente servicio post venta. Nuestros técnicos se encuentran a su disposición tanto para despejar cualquier duda sobre su producto Eurotime controls como para resolver algún desperfecto.<br /><br />
                    Nuestros productos cuentan con 2 años de garantía y service oficial . La garantía tendrá validez con la presentación de la correspondiente documentación de compra. Quedan exceptuados de esta garantía todas las fallas, roturas, accidentes o desgastes producidos por el uso indebido del producto. La batería no esta cubierta por la garantía.<br /><br />
                    Los productos Eurotime Controls (Diletta, Flash) están fabricados bajo las mas altas exigencias de calidad y acorde a las normas internacionales de seguridad.</p>
                        }
                    </div>
                    <div id="service-ubicacion">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6967.415210076521!2d-58.534248771710544!3d-34.49826574449094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb060c5f0946b%3A0x142922b241dd9695!2sEurotime%20Sa!5e0!3m2!1ses-419!2sar!4v1589897697935!5m2!1ses-419!2sar" id="service-map" title="service-map" aria-hidden="false"></iframe>
                        <div id="service-ubicacion-text">
                            <div className="service-ubicacion-line"><div className="service-ubicacion-icon" style={{ backgroundImage: 'url(../imgs/clock.svg)' }}></div>lunes a viernes, 7-12 y 12.30-16 hs</div>
                            <div className="service-ubicacion-line"><div className="service-ubicacion-icon" style={{ backgroundImage: 'url(../imgs/phone.svg)' }}></div>4717 2008 int. 234</div>
                            <div className="service-ubicacion-line"><div className="service-ubicacion-icon" style={{ backgroundImage: 'url(../imgs/location.svg)' }}></div>Entre Ríos 2704, Martínez, Buenos Aires, Argentina.</div>
                            <span>En caso de encontrarse lejos, por favor comuníquese para encontrar la mejor solución.</span>
                        </div>
                    </div>
                </div>
                {props.home === '/relojes' ? <div id="service-advice-container">
                    <h3 id="service-advice-title">CONSEJOS PARA EL CUIDADO DE SU RELOJ:</h3>
                    <div className="service-advice">
                        <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/relojes/service/temperature.svg)' }}></div><p>No exponga su reloj a temperaturas extremas. Puede afectar su funcionamiento o aspecto.</p>
                    </div>
                    <div className="service-advice">
                        <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/relojes/service/chemical.svg)', flex: '0 0 30px', margin: '0 42px 0 5px' }}></div><p>Evite el contacto con disolventes, detergentes, perfumes, cosméticos que pueden dañar las juntas o el aspecto de su reloj.</p>
                    </div>
                    <div className="service-advice">
                        <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/relojes/service/magnet.svg)', flex: '0 0 30px', margin: '0 42px 0 5px' }}></div><p>Colocar su reloj cerca de dispositivos electrónicos que pueden tener campo magnético potente, podría afectar el funcionamiento del reloj dependiendo de la sensibilidad del mismo.</p>
                    </div>
                    <div className="service-advice">
                        <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/relojes/service/drawer.svg)' }}></div><p>Guarde su reloj apropiadamente para protegerlo del polvo y la humedad. Puede conservarlo en su estuche original.</p>
                    </div>
                    <div className="service-advice">
                        <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/relojes/service/cleaning.svg)' }}></div><p>Limpie su reloj regularmente con un paño seco. En caso de ser resistente al agua, puede utilizar un paño húmedo.</p>
                    </div>
                    <div className="service-advice">
                        <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/relojes/service/service2.svg)', flex: '0 0 33px', margin: '0 41px 0 3px' }}></div><p>Para mantener su reloj en buen estado es recomendable realizar un service cada 2 o 3 años para su limpieza interna y reemplazo de batería gastada.</p>
                    </div>
                </div>
                    :
                    <div id="service-advice-container">
                        <h3 id="service-advice-title">CONSEJOS PARA EL CUIDADO DE SU PRODUCTO:</h3>
                        <div className="service-advice">
                            <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/electrico/service/tech.svg)' }}></div><p>Se recomienda que todos los productos sean instalados bajo la supervisión  de técnicos especializados.</p>
                        </div>
                        <div className="service-advice">
                            <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/electrico/service/charger.svg)', flex: '0 0 30px', margin: '0 42px 0 5px' }}></div><p>Para la linea de productos 20970/20972 asegurar 24 hs de carga antes de su instalación.</p>
                        </div>
                        <div className="service-advice">
                            <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/electrico/service/sun.svg)', flex: '0 0 30px', margin: '0 42px 0 5px' }}></div><p>No exponga los productos Eurotime controls al sol por largos periodos de tiempo.</p>
                        </div>
                        <div className="service-advice">
                            <div className="service-advice-image" style={{ backgroundImage: 'url(../imgs/electrico/service/signal.svg)' }}></div><p>El alcance de los termostatos linea 26900 varia según la cantidad de obstáculos entre el receptor y el termostato.</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default ServiceView