import React, { useState, useEffect } from 'react';
import Carousel from '../../shared/Carousel'
import ColorSelector from '../../shared/ColorSelector'
import Details from './Details'
import './ProductCard.css'

function ProductView(props) {

  const [imageURLs, setImageURLs] = useState(props.product.colors ? props.product.colors[0].imageURLs.map(url => [url, 'center', 'contain']) : props.product.imageURLs.map(url => [url, 'center', 'contain']))
  const [productCode, setProductCode] = useState(props.product.colors ? props.product.colors[0].code : props.product.code)
  const [selectedImage, setSelectedImage] = useState(props.product.colors ? props.product.colors[0].imageURLs[0] : props.product.imageURLs[0])

  const changeColor = color => {
    for (let i = 0; i < props.product.colors.length; i++) {
      if (props.product.colors[i].name === color) {
        setImageURLs(props.product.colors[i].imageURLs.map(url => [url, 'center', 'contain']))
        setProductCode(props.product.colors[i].code)
      }
    }
  }

  useEffect(() => {
    setImageURLs(props.product.colors ? props.product.colors[0].imageURLs.map(url => [url, 'center', 'contain']) : props.product.imageURLs.map(url => [url, 'center', 'contain']))
    setProductCode(props.product.colors ? props.product.colors[0].code : props.product.code)
  }, [props.product])

  return (
    <div id="product-view-container" style={{ ...props.style }}>
      <button id="close-button" style={{ backgroundImage: 'url(../imgs/close.svg)' }} onClick={props.closeHandler} ></button>
      <div id="product-view-left-container">
        {props.product.filters.marca ? props.product.filters.marca.toLowerCase() === 'flash' ? <img className="product-view-france" src="../imgs/electrico/productos/france.svg" alt="Made in France" /> : null : null }
        <Carousel imageURLs={imageURLs} arrows={true} imageWidth={'300px'} imageHeight={'300px'} setSelectedImage={(image) => setSelectedImage(image)} />
        <p id="product-code">{productCode}</p>
        {props.product.colors ? <ColorSelector colors={props.product.colors.map(color => color.name)} changeColor={changeColor} circleSize='15px' style={{ width: props.product.colors.length * 25 + "px", margin: 'auto' }} /> : null}
      </div>
      <div id='product-view-triangle' style={{ backgroundImage: 'url(../imgs/triangle.svg)' }}></div>
      <div id="product-view-right-container">
        <Details line={props.product.line} description={props.product.description} image={selectedImage} manual={props.product.manualURL} connectionScheme={props.product.connectionSchemeURL} home={props.home} />
      </div>
    </div>
  );
}

export default ProductView;