import React, { useEffect } from 'react'
import './ContactView.css'
import SectionCover from '../shared/SectionCover'
import ContactForm from './ContactForm'

function ContactView(props) {

    useEffect(() => { document.title = 'Contáctenos | Eurotime' }, [])

    return (
        <div id="contact-view-container" style={{ ...props.style }}>
            <SectionCover image="linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(../imgs/contact-cover.jpg)" position="bottom" />
            <div id="contact-view-main">
                <div id="contact-view-main-content">
                    <div id="contact-view-phone">
                        <h4 id="contact-view-phone-title">LLÁMENOS</h4>
                        <p id="contact-view-phone-text">Podrá encontrarnos en nuestras oficinas de lunes a viernes de 09 a 17 hs</p>
                        <div id="contact-view-text">
                            <div id="contact-view-extensions">
                                <div id="contact-view-phone-number" onClick={() => window.open('tel:+5491147172008', '_blank')}>
                                    <div>
                                        <span>+54 9 11 4717 2008</span>
                                        <p>Central</p>
                                    </div>
                                    <div className="contact-view-extension-image" style={{ backgroundImage: 'url(./imgs/phone.svg)' }}></div>
                                </div>
                                <div id="contact-view-phone-number" onClick={() => window.open('https://api.whatsapp.com/send?phone=+5491136819510', '_blank')}>
                                    <div>
                                        <span>+54 9 11 3681 9510</span>
                                        <p>Whatsapp</p>
                                    </div>
                                    <div className="contact-view-extension-image" style={{ backgroundImage: 'url(./imgs/wa.svg)' }}></div>
                                </div>
                                <div className="contact-view-extension">
                                    <div>
                                        <span>VENTAS</span>
                                        <p>Interno</p>
                                    </div>
                                    <div className="contact-view-extension-image">242</div>
                                </div>
                                <div className="contact-view-extension">
                                    <div>
                                        <span>SERVICE</span>
                                        <p>Interno</p>
                                    </div>
                                    <div className="contact-view-extension-image">234</div>
                                </div>
                                <div className="contact-view-extension">
                                    <div>
                                        <span>COBRANZAS</span>
                                        <p>Interno</p>
                                    </div>
                                    <div className="contact-view-extension-image">221</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="contact-view-form-container">
                        <h3>CONTACTE NUESTRO SERVICIO AL CLIENTE:</h3>
                        <p id="contact-view-form-text">En Eurotime estamos a su disposición para cualquier consulta o sugerencia.</p>
                        <ContactForm id="contact-view-form" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactView;