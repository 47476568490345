import React from 'react'
import { Link } from "react-router-dom"
import './home.css'

function Home() {

  return (
    <div className="Home">
      <Link className="Home-link" to="/relojes"><img src="../imgs/logo-relojes-vertical.svg" alt="Eurotime relojes logo" /><button>INGRESAR</button></Link>
      <Link className="Home-link" to="/controls"><img src="../imgs/logo-controls-vertical.svg" alt="Eurotime controls logo" /><button>INGRESAR</button></Link>
    </div>
  );
}

export default Home;