import React from 'react'
import './Filter.css'

function Filter(props) {

    const filterButtonClickHandler = (e) => {
        if (e.target !== this) {
            const filterCheckbox = e.currentTarget.childNodes[0]
            if (filterCheckbox.style.backgroundColor === 'black') {
                filterCheckbox.style.backgroundColor = ''
                props.filterButtonClickHandler(false, props.filterIndex, e.currentTarget.getAttribute('buttonname'))
            } else {
                filterCheckbox.style.backgroundColor = 'black'
                props.filterButtonClickHandler(true, props.filterIndex, e.currentTarget.getAttribute('buttonname'))
            }
        }
    }

    const buttons = props.buttons.map((button, i) => (<button className="filter-button" buttonname={button} onClick={filterButtonClickHandler} key={i}><div className="filter-checkbox"></div> {button}</button>))

    return (
        <div className="filter-container" style={{ ...props.style }}>
            <h4 id={"filter-title-" + props.filterIndex} className="filter-title" >{props.title}</h4>
            {buttons}
        </div>

    )
}

export default Filter