import React from 'react';
import './Details.css'

function Details(props) {

    return (
        <div id="details-container" style={{ ...props.style }}>
            <h3 id="details-title">{props.line.toUpperCase()}</h3>
            <hr id="details-hr" />
            <div id="details-text">
                {props.description.text.map((text, index) => <p class="details-text-p" key={index}>{text}</p>)}
                {props.description.items.map((item, index) =>
                    <div className="details-item" key={index + 1} ><div className="details-item-bullet"></div><p className="details-item-text">{item}</p></div>
                )}
            </div>
            {props.image || props.manual || props.connectionScheme ?
                <div className="details-buttons-container">
                    {props.home === '/relojes' ? <a id="details-manual-download" href={props.image} download>DESCARGAR IMAGEN</a> : null}
                    {props.manual ? <a id="details-manual-download" href={props.manual} download="">DESCARGAR MANUAL</a> : null}
                    {props.home === '/electrico' && props.connectionScheme ? <a id="details-manual-download" href={props.connectionScheme} download="">DESCARGAR ESQUEMA DE CONEXIÓN</a> : null}
                </div> : null}
        </div>
    );
}

export default Details;