import React, { useState, useEffect, useCallback } from 'react'
import './Carousel.css'

function Carousel(props) {

    const [selectedImgIndex, setSelectedImgIndex] = useState(0)
    const [stopped, setStopped] = useState(!props.autoplay)

    const showPrevImg = useCallback(() => {
        setSelectedImgIndex((prevImgIndex) => {
            return prevImgIndex === 0 ? props.imageURLs.length - 1 : prevImgIndex - 1
        })
    }, [props.imageURLs.length])

    const showNextImg = useCallback(() => {
        setSelectedImgIndex((prevImgIndex) => {
            return prevImgIndex === props.imageURLs.length - 1 ? 0 : prevImgIndex + 1
        })
    }, [props.imageURLs.length])

    const circlePress = (index) => {
        setSelectedImgIndex(index)
    }

    useEffect(() => {
        setSelectedImgIndex(0)
    }, [props.imageURLs])

    useEffect(() => {
        const myCarouselInterval = setInterval(() => showNextImg(), 4500)
        if (stopped) clearInterval(myCarouselInterval)

        return () => clearInterval(myCarouselInterval)
    }, [showNextImg, showPrevImg, stopped])

    useEffect(() => {
        if (props.setSelectedImage) {
            props.setSelectedImage(props.imageURLs[selectedImgIndex][0])
        }
    }, [selectedImgIndex, props])


    return (
        <div className="carousel-container" style={{ ...props.style, width: props.imageWidth }}>

            <div id="carousel-content-container" style={{ width: props.imageWidth, height: props.imageHeight, marginLeft: selectedImgIndex * -100 + '%' }}>
                {props.imageURLs.map((imageURL, index) => <div className="carousel-element" key={index} style={{ backgroundImage: 'url(' + imageURL[0] + ')', backgroundPosition: imageURL[1], backgroundSize: imageURL[2] }}>{props.content ? props.content[index] ? <div className="carousel-content">{props.content[index]}</div> : null : null}</div>)}
            </div>

            {props.imageURLs.length > 1 && props.circles ? <div id='carousel-circles-container' onMouseEnter={() => setStopped(true)} onMouseLeave={() => { if (props.autoplay) setStopped(false) }} style={{ left:  'calc(50% - ' + ((props.imageURLs.length * 25 / 2)) + 'px)'}}>
                {props.imageURLs.map((url, index) => <button className={index === selectedImgIndex ? "carousel-circle-button carousel-circle-button-active" : "carousel-circle-button"} key={index} onClick={() => circlePress(index)} ></button>)}
            </div> : null}

            {props.imageURLs.length > 1 && props.arrows ? <div id='carousel-arrows-container'>
                <button id="carousel-button-left" className="carousel-arrow-button" onClick={showPrevImg} ><img src="../imgs/arrow-left.svg" alt="anterior" /></button>
                <p>{selectedImgIndex + 1 + ' / ' + props.imageURLs.length}</p>
                <button id="carousel-button-right" className="carousel-arrow-button" onClick={showNextImg} ><img src="../imgs/arrow-right.svg" alt="siguiente" /></button>
            </div> : null}

        </div>
    )
}

export default Carousel