import React from 'react';
import './Pagination.css'

function Pagination(props) {

    const subtract = props.currentPage > 1 ? () => props.clickHandler(false) : null
    const add = props.currentPage < props.pages ? () => props.clickHandler(true) : null

  return (
    <div id="pagination-container" style={{ ...props.style }}>
        <button className={props.currentPage > 1 ? "pagination-button" : "pagination-button pagination-button-disabled"} onClick={subtract} ><img src="../imgs/arrow-left.svg" alt="anterior" /> Anterior</button>
        <p id="pagination-text">{props.currentPage + ' / ' + props.pages}</p>
        <button className={props.currentPage < props.pages ? "pagination-button" : "pagination-button pagination-button-disabled"} onClick={add} >Siguiente <img src="../imgs/arrow-right.svg" alt="siguiente" /></button>
    </div>
  );
}

export default Pagination;