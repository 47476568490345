import React from 'react'
import './ColorSelector.css'

function ColorSelector(props) {

  const colorButtons = props.colors.map((color, index) => {
    if (index === props.colors.length - 1) {
      return <button className="color-button" key={props.colors.length - 1} style={{ background: props.colors[props.colors.length - 1], margin: '0', height: props.circleSize, width: props.circleSize }} onClick={() => props.changeColor(props.colors[props.colors.length - 1])}></button>
    }
    return <button className="color-button" key={index} style={{ background: color, height: props.circleSize, width: props.circleSize }} onClick={() => props.changeColor(color)}></button>
  })

  return (
    <div style={{ ...props.style }}>
      {colorButtons}
    </div>
  )
}

export default ColorSelector