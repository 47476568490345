import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

function NavBar(props) {

    const navbarRef = useRef(null)
    const navbarButtonsContainer = useRef(null)

    const [showFullMenu, setShowFullMenu] = useState(false)

    //Changing navbar color on window scroll
    useEffect(() => {
        if (window.scrollY < 60 && !showFullMenu) {
            navbarRef.current.style.backgroundColor = 'transparent'
        } else {
            navbarRef.current.style.backgroundColor = 'rgba(0,0,0,0.85)'
        }
    }, [showFullMenu])

    useEffect(() => {
        //Fixing navbar on window size change
        const changeNavbarSize = () => {
            if (window.innerWidth > 850) {
                navbarRef.current.style.height = '70px'
            } else {
                navbarRef.current.style.height = '62px'
            }
        }

        const changeNavbarColor = () => {
            if (window.scrollY < 60 && (window.innerWidth > 850 || !showFullMenu)) {
                navbarRef.current.style.backgroundColor = 'transparent'
            } else {
                navbarRef.current.style.backgroundColor = 'rgba(0,0,0,0.85)'
            }
        }

        //ComponentWillMount
        window.addEventListener('resize', changeNavbarSize)
        window.addEventListener('resize', changeNavbarColor)
        window.addEventListener('scroll', changeNavbarColor)

        //ComponentWillUnmount 
        return () => {
            window.removeEventListener('resize', changeNavbarSize)
            window.removeEventListener('resize', changeNavbarColor)
            window.removeEventListener('scroll', changeNavbarColor)
        }
    }, [showFullMenu])

    //Setting active button
    const [activeButton, setActiveButton] = useState(window.location.pathname.slice(1,8).toUpperCase() === 'RELOJES' ? window.location.pathname.slice(9).toUpperCase() : window.location.pathname.slice(11).toUpperCase())
    const buttonClickHandler = (e) => {
        window.scrollTo(0, 0)
        setShowFullMenu(false)
        setActiveButton(e.target.innerHTML)
    }

    //Button creation
    const buttons = props.buttons.map((button, i) => <Link to={(props.home === '/electrico' ? '/controls' : '/relojes') + '/' + button.toLowerCase()} key={i} style={{ margin: 'auto' }}><button className={button === activeButton ? 'navbar-button navbar-button-active' : 'navbar-button'} onClick={buttonClickHandler} >{button}</button></Link>)

    return (
        <div id="navbar" ref={navbarRef} style={{ ...props.style, height: window.innerWidth > 850 ? '70px' : showFullMenu ? '255px' : '62px' }}>
            <div id="navbar-actual-container">
                <Link to={props.home === '/electrico' ? '/controls' : '/relojes'}><img id="navbar-logo" src={props.logo} alt='Eurotime logo' onClick={buttonClickHandler} style={props.home === '/electrico' ? {filter: 'brightness(0) invert(1)'} : null} /></Link>
                <div id="navbar-buttons-container" ref={navbarButtonsContainer} >
                    {buttons}
                </div>
                <img id="navbar-menu-button" src='../imgs/menu.svg' alt='Menu' onClick={() => {setShowFullMenu(prevShowFullMenu => !prevShowFullMenu)}} />
            </div>
        </div>
    )
}

export default NavBar