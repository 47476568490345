import React, { useState } from 'react'
import './ContactForm.css'

function ContactForm(props) {

    const [showModal, setShowModal] = useState(false)

    const [mensaje, setMensaje] = useState('')
    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [email, setEmail] = useState('')

    const handleSubmit = e => {
        e.preventDefault()
        if (mensaje !== '' && nombre !== '' && telefono !== '' && email !== '') {
            setShowModal(true)

            let eurotimeForm = {};

            eurotimeForm.mensaje = mensaje;
            eurotimeForm.nombre = nombre;
            eurotimeForm.telefono = telefono;
            eurotimeForm.email = email;

            const jsonData = JSON.stringify(eurotimeForm);


            let xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://newsletterservice.com.ar/landing/api/eurotime');

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        alert("Formulario enviado con éxito. Lo contactaremos a la brevedad.")
                        setShowModal(false)
                    } else {
                        alert("Error al enviar el formulario, por favor vuelva a intentarlo o comuníquese por otra vía.")
                        setShowModal(false)
                    }
                }
            };
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(jsonData);

        } else {
            alert('Por favor complete todos los campos antes de enviar el formulario.')
        }
    }

    return (
        <div id="contact-form-container">
            <form id="contact-form">
                <div className="contact-form-input-group">
                    <label>Mensaje</label>
                    <textarea id="message-input" rows="5" value={mensaje} onChange={e => { setMensaje(e.target.value) }} />
                </div>
                <div className="contact-form-input-group">
                    <label>Nombre</label>
                    <input id="name-input" type="text" autoComplete="off" value={nombre} onChange={e => { setNombre(e.target.value) }} />
                </div>
                <div className="contact-form-input-group">
                    <label>Teléfono</label>
                    <input id="phome-input" type="text" autoComplete="off" value={telefono} onChange={e => { setTelefono(e.target.value) }} />
                </div>
                <div className="contact-form-input-group">
                    <label>Email</label>
                    <input type="email-input" autoComplete="off" value={email} onChange={e => { setEmail(e.target.value) }} />
                </div>
                <button id="form-submit" type="submit" onClick={e => { handleSubmit(e) }}>ENVIAR</button>
            </form>
            {showModal ? <div id="contact-form-modal">
                <div id="contact-form-modal-inner">
                    <img id="contact-form-modal-icon" src="./imgs/loading.gif" alt="loading..." />
                    <p>Enviando el formulario, por favor no cierres la página</p>
                </div>
            </div> : null}
        </div>
    );
}

export default ContactForm;