import React, { useState, useEffect } from 'react';
import './CoverBar.css'

function CoverBar(props) {

    const [displayedElements, setDisplayedElements] = useState()

    useEffect(() => {
        let myCarouselInterval

        const barElements = [(<div className="cover-bar-element" key={0}>
            <img className="cover-bar-image" src="../imgs/warranty.svg" alt="garantía" />
            <p className="cover-bar-text">GARANTÍA EN TODOS LOS PRODUCTOS</p>
        </div>), (<div className="cover-bar-element" key={1}>
            <img className="cover-bar-image" src="../imgs/service.svg" alt="service" />
            <p className="cover-bar-text">SERVICE OFICIAL</p>
        </div>), (<div className="cover-bar-element" key={2}>
            <img className="cover-bar-image" src={props.home === '/relojes' ? '../imgs/materials.svg' : '../imgs/ignifugo.svg'} alt="materiales" />
            <p className="cover-bar-text">{props.home === '/relojes' ? 'FABRICADOS CON MATERIALES SELECCIONADOS' : 'FABRICADOS CON MATERIALES IGNIFUGOS'}</p>
        </div>)]

        const changeCoverBarOnWidthChange = () => {
            clearInterval(myCarouselInterval)
            window.innerWidth < 1101 ? setDisplayedElements(barElements[0]) : setDisplayedElements(barElements)
            if (window.innerWidth < 1101) myCarouselInterval = carouselInterval()
        }

        const carouselInterval = () => window.innerWidth < 1101 ? setInterval(function () {
            setDisplayedElements(prevDisplayedElement => {
                const index = barElements.indexOf(prevDisplayedElement)
                return index === barElements.length - 1 ? barElements[0] : barElements[index + 1]
            })
        }, 2500) : () => null

        changeCoverBarOnWidthChange()

        window.addEventListener('resize', changeCoverBarOnWidthChange)

        return () => {
            window.removeEventListener('resize', changeCoverBarOnWidthChange)
            clearInterval(myCarouselInterval)
        }

    }, [props.home])

    return (
        <div id='cover-bar' style={{ ...props.style }}>
            <div id='cover-bar-inner'>
                {displayedElements}
            </div>
        </div>
    )
}

export default CoverBar