import React from 'react'
import './SectionCover.css'

function SectionCover(props) {

    return (
        <div id='section-cover-container' style={{ backgroundImage: props.image, backgroundPosition: props.position }}><h2 id="section-cover-title" style={{ textAlign:  props.home === '/relojes' ? 'right' : 'center' }}>{props.title}</h2></div>
    )
}

export default SectionCover