import React, { useEffect } from 'react'
import './HistoryView.css'
import SectionCover from '../shared/SectionCover'
import CoverBar from '../shared/CoverBar'

function HistoryView(props) {

  useEffect(() => { document.title = 'Historia | Eurotime' }, [])

  return (
    <div id="history-view-container">
      <SectionCover image="linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(../imgs/history-cover.jpg)" position="center" />
      <div id="history-view-text">
        <h2 id="service-title">NUESTRA HISTORIA</h2>
        {props.home === '/relojes' ? 
        <p>Eurotime S.A., es una empresa de origen alemán con base en Argentina especializada en el desarrollo, diseño y comercialización de relojes.
          Con 40 años en el mercado, Eurotime tiene presencia en todo el país, innovando continuamente para ofrecer a nuestros clientes una amplia gama de productos de excelencia.
          El compromiso, la seriedad y la responsabilidad  han sido desde el comienzo los valores centrales para posicionarnos como líderes en distintos segmentos del mercado. Contamos con un excelente grupo humano que lo ha hecho posible.
          Nuestra trayectoria fortalecida por los lazos de confianza con nuestros clientes, forjaron la base para que Eurotime hoy se encuentre entre las primeras marcas, destacándose por brindar la mejor relación precio-calidad.
        <br /><br />
        La colección Eurotime está compuesta por modelos de alta calidad y tecnología avanzada. Comenzamos creando nuestras líneas de relojes con diseños clásicos de estilo europeo y con el transcurso del tiempo esta colección fue ampliándose, incorporado nuevas líneas de productos, reinventándose y evolucionando a través de las distintas tecnologías según la época.
        Cabe resaltar que contamos con un stock permanente de nuestros principales relojes y un excepcional servicio de post-venta y garantía.
        </p>
        :
        <p>Eurotime S.A., es una empresa de origen alemán con base en Argentina especializada en el desarrollo, diseño y comercialización de materiales eléctricos y productos afines. Con 40 años en el mercado, hemos logrado tener presencia en todo el país, con nuestras marcas Diletta y Flash, innovando continuamente para ofrecer a nuestros clientes una amplia gama de productos de excelencia. El compromiso, la seriedad y la responsabilidad han sido desde el comienzo los valores centrales para posicionarnos como líderes en distintos segmentos del mercado. Contamos con un excelente grupo humano que lo ha hecho posible. Nuestra trayectoria fortalecida por los lazos de confianza con nuestros clientes, forjaron la base para que Eurotime Controls (Diletta y Flash) hoy se encuentren entre las primeras marcas, destacándose por la mejor tecnología y calidad.
        <br /><br />
        Eurotime Controls cuenta con una completa línea de interruptores, termostatos y contactores. Con el transcurso del tiempo se fueron incorporando nuevos productos, siempre teniendo en cuenta la misma premisa: innovación y calidad. Cabe resaltar que contamos con un stock permanente de nuestros principales productos y un excepcional servicio de post-venta y garantía.
        </p>
        }
        <CoverBar home={props.home} style={{ backgroundColor: 'transparent', marginTop: '50px', width: '120%' }} />
      </div>
    </div>
  );
}

export default HistoryView;