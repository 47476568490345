import React, { useEffect, useRef } from 'react'
import Filter from './Filter'
import './FilterList.css'

function FilterList(props) {

  const filterListTextContainer = useRef(null)
  const filterListIconTitle = useRef(null)
  const filterListIcon = useRef(null)

  const smallMenuClickHandler = () => {
    if (filterListTextContainer.current.style.height === 'auto' || filterListTextContainer.current.style.height === '') {
      Object.assign(filterListTextContainer.current.style, {transition: 'top 0.5s ease-in-out, visibility 0.5s ease-in-out', height: 'calc(100vh - 112px)', visibility: 'visible', top: 'calc(-100vh + 112px)'})
      filterListIconTitle.current.innerHTML = 'Esconder filtros'
      filterListIconTitle.current.style.margin = '16px auto 0 auto'
      filterListIcon.current.style.display = 'none'
    } else {
      filterListIconTitle.current.innerHTML = 'Filtrar'
      filterListIconTitle.current.style.margin = '16px auto 0 5px'
      filterListIcon.current.style.display = 'block'
      Object.assign(filterListTextContainer.current.style, {transition: '0.5s ease-in-out', height: '', visibility: 'hidden', top: '0'})
    }
  }

  useEffect(() => {
    //Fixing filters on window size change
    const changeFiltersOnWidthChange = () => {
      if (window.innerWidth > 850) {
        filterListIconTitle.current.innerHTML = 'Filtrar'
        filterListIconTitle.current.style.margin = '16px auto 0 5px'
        filterListIcon.current.style.display = 'block'
        Object.assign(filterListTextContainer.current.style, {transition: '0.5s ease-in-out', height: 'auto', visibility: 'visible', top: '80px'})
      }
    }

    //ComponentWillMount
    window.addEventListener('resize', changeFiltersOnWidthChange)

    //ComponentWillUnmount 
    return () => {
      window.removeEventListener('resize', changeFiltersOnWidthChange)
    }
  }, [])

  const filters = props.filters.map((filter, index) => {
    return <Filter title={filter.title} buttons={filter.buttons} filterIndex={index} filterButtonClickHandler={props.filterButtonClickHandler} key={index} />
  })

  return (
    <div id="filter-list-container" style={{ ...props.style }}>
      <div id="filter-list-text-container" ref={filterListTextContainer}>
        <button className="filter-list-back" onClick={smallMenuClickHandler}><img src="../imgs/back.svg" alt="back" /></button>
        <div className="filter-list-text-small-container">
          {filters}
        </div>
      </div>
      <div id="filter-list-small-menu-container" onClick={smallMenuClickHandler}>
        <img id="filter-list-icon" ref={filterListIcon} src="../imgs/filter.svg" alt="Filter" />
        <p id="filter-list-icon-title" ref={filterListIconTitle}>Filtrar</p>
      </div>
    </div>
  )
}

export default FilterList